//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
.center-screen {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('spinner.gif') 
                50% 50% no-repeat rgba(249, 249, 249, 0.411);
  }